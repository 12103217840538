<template>
  <App
    left-arrow
    title="价格明细"
  >
    <Tabs v-model="tabActive">
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
        <Card
          v-for="$e in datas[tabActive]"
          :key="$e.featureCode"
        >
          <template slot="title">
            <div class="card-info">
              <h3 class="name">{{$e.name}}</h3>
              <span class="tag">{{$e.price}}</span>
            </div>
            <div
              v-if="tabActive === 'feature'"
              class="description"
            >
              <h4 class="accountMobile">{{$e.code}}</h4>
            </div>
          </template>
        </Card>
      </Tab>
    </Tabs>
  </App>
</template>

<script>
import Card from '_c/card'
import { Tab, Tabs } from 'vant'

export default {
  name: 'priceDetail',
  components: { Tab, Tabs, Card },
  data () {
    return {
      tabActive: 'feature',
      tabsData: [
        { title: '选装包', label: 'feature' },
        { title: '折扣信息', label: 'discount' }
      ],
      featureList: [],
      discountList: [],
      datas: {}
    }
  },
  methods: {
    formatNumber (num) {
      let result = parseFloat(num);
      if (isNaN(result)) {
        alert('传递参数错误，请检查！');
        return false;
      }
      result = Math.round(num * 100) / 100;
      let s_x = result.toString(); //将数字转换为字符串
      let pos_decimal = s_x.indexOf('.'); //小数点的索引值
      // 当整数时，pos_decimal=-1 自动补0
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += '.';
      }
      // 当数字的长度< 小数点索引+2时，补0
      while (s_x.length <= pos_decimal + 2) {
        s_x += '0';
      }
      return s_x;
    }
  },
  mounted () {
    const pageParam = this.$route.query
    const featureList = pageParam.featureList.map(item => {
      return {
        name: item.featureName,
        price: this.formatNumber(item.price),
        code: item.featureCode
      }
    })
    const discountList = [
      { name: '统一折扣', price: pageParam.discountUniteTotal },
      { name: '订单内特殊折扣总额', price: pageParam.discountSpecialTotal || '0.00' }
    ]
    this.datas = {
      feature: featureList,
      discount: discountList
    }
  }
}
</script>
<style lang="less" scoped>
.card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name {
    width: 500px;
    margin-right: 20px;
  }

  .tag {
    display: inline-block;
    padding: 0 10px;
    color: @blue;
    font-size: 24px;
    height: 42px;
    line-height: 42px;
  }
}
</style>
