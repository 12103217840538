import { render, staticRenderFns } from "./priceDetail.vue?vue&type=template&id=56eb6156&scoped=true"
import script from "./priceDetail.vue?vue&type=script&lang=js"
export * from "./priceDetail.vue?vue&type=script&lang=js"
import style0 from "./priceDetail.vue?vue&type=style&index=0&id=56eb6156&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56eb6156",
  null
  
)

export default component.exports